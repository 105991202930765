import React from 'react';
import {
    SpaceBetween,
    Container,
    ExpandableSection,
    ColumnLayout,
    Box,
    Table,
    Spinner,
    StatusIndicator
} from '@cloudscape-design/components';
import ControlDescription from 'src/utils/ControlDescription'; // Adjust the import path as needed
import { ControlAttributesMetadata } from '@amzn/chub-model-typescript-client';

interface ControlMetadataContentProps {
    controlAttributesData: ControlAttributesMetadata | null;
    control: any;
}

/**
 * Renders the metadata content for the control.
 * This function is responsible for displaying control metadata and attributes data sources.
 * 
 * @returns {JSX.Element} The rendered metadata content
 */
const ControlMetadataContent: React.FC<ControlMetadataContentProps> = ({ controlAttributesData, control }) => {
    if (!controlAttributesData) {
        return <StatusIndicator type="error">No metadata available</StatusIndicator>;
    }
    const attributesMetadata = controlAttributesData.attributesMetadata || [];
    return (
        <SpaceBetween size="l">
            <Container>
                <ExpandableSection headerText="Control Metadata" defaultExpanded={true}>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <div>
                                <Box variant="awsui-key-label">Control Name</Box>
                                <div>{control?.controlName}</div>
                            </div>
                            <div>
                                <Box variant="awsui-key-label">Control Title</Box>
                                <div>{control?.controlSubTitle}</div>
                            </div>
                            <div>
                                <Box variant="awsui-key-label">Control Schedule</Box>
                                <div>{control?.controlExecutionType}</div>
                            </div>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <div>
                                <Box variant="awsui-key-label">Control Description</Box>
                                <ControlDescription description={control?.controlDescription || ''} />
                            </div>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExpandableSection>
            </Container>
            {
              control?.controlInstructions ? (
                <Container>
                  <ExpandableSection headerText="Key Insights">
                    <ControlDescription description={control?.controlInstructions || ''} />
                  </ExpandableSection>
                </Container>) : null
            }
            <Container>
                <ExpandableSection headerText="Attributes Data Sources">
                    {attributesMetadata.length > 0 ? (
                        <Table
                            items={attributesMetadata}
                            columnDefinitions={[
                                { id: "name", header: "Name", cell: item => item.name },
                                { id: "datasource", header: "Data Source", cell: item => item.dataSource },
                                { id: "notes", header: "Notes", cell: item => item.notes }
                            ]}
                            variant="embedded"
                        />
                    ) : (
                        <Spinner />
                    )}
                </ExpandableSection>
            </Container>
        </SpaceBetween>
    );
};

export default ControlMetadataContent;
