import React, {useMemo} from 'react';
import {Outlet, useParams, useNavigate} from "react-router-dom";
import {
    AppLayout,
    Container,
    Box,
    Grid,
    Alert,
    Spinner,
    Button,
    SpaceBetween,
} from '@cloudscape-design/components';
import TopNav from 'src/components/navigation/TopNav';
import {Breadcrumbs} from 'src/common/Breadcrumbs';
import {PageHeader} from 'src/components/layout/PageHeader';
import ControlDescription from 'src/utils/ControlDescription';
import {useUserAlias} from "src/common/hooks/useUserAlias";
import {useControls} from "src/common/ControlContext";
import {  ControlMetadata } from '@amzn/chub-model-typescript-client';
import { NavigationPaths } from 'src/common/config/ApiConstants';


/**
 * ControlHomePage Component
 *
 * This is a generic component that generates a Control Home page for any control
 * within the Controllership Hub. It dynamically renders content based on the
 * Control ID provided in the URL.
 *
 * The component utilizes custom hooks to retrieve the user alias and the complete
 * list of available controls. It then identifies and displays the specific control
 * information by matching the Control ID from the URL parameters with the control
 * list.
 *
 * This approach allows for a single, reusable component to handle the display of
 * any control's home page, enhancing maintainability and consistency across the
 * application.
 */
export const ControlHomePage = () => {
    // Get the user alias using a custom hook
    const {alias} = useUserAlias();

    // Get the list of controls using a custom hook
    const {controls} = useControls();

    // Extract the controlId from the URL parameters
    const {controlId} = useParams<{ controlId: string }>();

    /**
     * Memoized function to find the current control
     *
     * This function searches for a control in the controls array that matches the controlId from the URL.
     * It returns the found control object or null if no match is found.
     * The function is memoized to optimize performance, only recalculating when controls or controlId change.
     */
    const control = useMemo(() => {
        return controls.find(c => c.controlId === controlId) || null;
    }, [controls, controlId]);

    // Import the useNavigate hook from react-router-dom (assumed)
const navigate = useNavigate();

/**
 * Handles the click event for a control button.
 * 
 * @param {ControlMetadata | null} control - The metadata of the clicked control, or null if no control is associated.
 */
const handleButtonClick = (control: ControlMetadata | null) => {
    // Check if the control has an external link
    if (control?.externalLink) {
        // If external link exists, open it in a new tab with security attributes
        window.open(control.externalLink, '_blank', 'noopener,noreferrer');
    } else if (control) {
        // If control exists but doesn't have an external link, navigate internally
        // Format the control type: convert to lowercase and replace underscores
        const controlType = control.controlType?.toLowerCase().replace(/_/g, '_');
        
        // Get the current URL
        window.location.href = `/${controlType}/${control.controlId}${NavigationPaths.summary}`;
    } else {
        // If control is null, navigate to the home page as a fallback
        window.location.href = '/';
    }
};

    return (
        <>
            {/* Top Navigation s*/}
            <div id="b">
                <div id="h" style={{position: "sticky", top: 0, zIndex: 1002}}>
                    <TopNav userAlias={alias}
                            issueLink="https://sim.amazon.com/issues/create?assignedFolder=cb133b70-fac3-42e7-86f2-575d040a086d"
                            aboutLink="https://w.amazon.com/bin/view/WWCF_IPAT/FinTech/Controllership/Controllership_Hub/"/>
                </div>
                <Outlet/>
            </div>
            <AppLayout
                navigationHide={true}
                disableContentPaddings={true}
                toolsHide={true}
                content={
                    <>
                        {/* ControllershipHub Page Header*/}
                        <PageHeader
                            title="Controllership Hub"
                            subtitle="Your one-stop location for consolidated and streamlined controllership work within the WW Amazon Stores Finance."
                            description="Finance Controllership Hub brings together the disparate controllership tools, streamlining the once-fragmented world of controllership into a unified and efficient ecosystem. It brings ease, efficiency, and convenience to controllership monitoring, reporting, and task tracking within World Wide Amazon Stores Finance. It is designed to transform how finance controllership processes are managed, monitored, and automated."/>
                        <Container>
                            {/* Breadcrumbs - Only shown when not on the home page */}
                            {
                                location.pathname !== '/' ?
                                    <Breadcrumbs
                                        items={[
                                            {text: "Controllership Hub", href: "/"},
                                            {text: `Control Home`, href: ''},
                                        ]}
                                    /> : undefined
                            }
                            {/* Control content - Shown when controls are loaded */}
                            {(controls.length > 0) ? (
                                <Box padding={"m"}>
                                    <Container>
                                        {/* Control Name */}
                                        <Box padding="s" fontSize="display-l" variant="h2">
                                            {control?.controlName || 'No control name'}
                                        </Box>
                                        {/* Control Subtitle */}
                                        <Box padding="s" variant="h3">
                                            {control?.controlSubTitle || 'No control SubTitle'}
                                        </Box>
                                        {/* Control Description */}
                                        <Box>
                                            <ControlDescription description={control?.controlDescription || ''}/>
                                        </Box>
                                        <SpaceBetween size="m">
                                            {/* Control Disclaimer - Shown if available */}
                                            {control?.controlDisclaimer &&
                                                <Alert statusIconAriaLabel="Warning" type="warning">
                                                    <ControlDescription description={control?.controlDisclaimer || ''}/>
                                                </Alert>}
                                            <Grid>
                                                {/* Perform Control Execution Button */}
                                                <Button
                                                    variant="primary"
                                                    iconAlign="right"
                                                    onClick={() => handleButtonClick(control)}
                                                    {...(control?.externalLink
                                                        ? {
                                                            target: "_blank",
                                                            rel: "noopener noreferrer",
                                                            iconName: "external"
                                                        }
                                                        : {})}
                                                >
                                                    Perform {control?.controlName || 'No control name'}
                                                </Button>
                                            </Grid>
                                        </SpaceBetween>
                                    </Container>
                                </Box>
                            ) : (
                                // Loading spinner shown when controls are not yet loaded
                                <Spinner/>
                            )}
                        </Container>
                    </>
                }
            />
        </>
    );
}