import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Table, SpaceBetween, Box, Pagination, TableProps, PropertyFilterProps, Button, Flashbar, Modal,
    ButtonDropdown, CollectionPreferencesProps
} from '@cloudscape-design/components';
import { ControlAttributesMetadata, RecordAttributes } from '@amzn/chub-model-typescript-client';
import { PropertyFilterComponent } from 'src/common/PropertyFilterComponent';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
import { TableHeaderWithInfo } from 'src/common/TableHeaderWithInfo';
import { apiCall } from 'src/utils/ApiCall';
import { getHandleSubmitEdit, getCurrentPageItems, filterItems, getHandleConfirm, getHandleSubmit, getHandleUndo, getPreferences }
    from 'src/common/ControlSummaryTableHelper'
import { generateColumnDefinitions, TextWithLineBreaks, getFlashbarMessage, getButtonDropdownItems } from 'src/common/ControlSummaryTableConfig';
import CustomCollectionPreferences from 'src/common/CustomCollectionPreferences';
import { useControls } from "src/common/ControlContext";
import { NavigationPaths } from 'src/common/config/ApiConstants';

/**
 * Interface defining the props for the ControlSummaryTable component
 */
interface TableUtilProps {
    controlData: RecordAttributes[][]; // Array of control data records
    controlAttributesData: ControlAttributesMetadata | null; // Metadata for control attributes
    isLoading: boolean; // Flag indicating if data is still loading
    controlId: string; // Unique identifier for the control
}

// Type aliases for better readability
export type SortingState<T> = TableProps.SortingState<T>;
export type PreferencesType = CollectionPreferencesProps.Preferences;

/**
 * ControlSummaryTable Component
 * 
 * This component renders a summary table for control data.
 * 
 * @param {TableUtilProps} props - The props for the component
 */
export const ControlSummaryTable: React.FC<TableUtilProps> = ({ controlData, controlAttributesData, isLoading, controlId }) => {
    const { alias } = useUserAlias();
    const { controls } = useControls();
    const [showBanner, setShowBanner] = useState(false);
    const columnDefinitionsRef = useRef<TableProps.ColumnDefinition<Record<string, any>>[]>([]);
    const [items, setItems] = useState<Record<string, any>[]>([]);
    const [filteredItems, setFilteredItems] = useState<Record<string, any>[]>([]);
    const [originalItems, setOriginalItems] = useState<Record<string, string>[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(300);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', body: '' });
    const [isDirty, setIsDirty] = useState(false);
    const location = useLocation();

    /**
    * Memoized function to find the current control
    *
    * This function searches for a control in the controls array that matches the controlId from the URL.
    * It returns the found control object or null if no match is found.
    * The function is memoized to optimize performance, only recalculating when controls or controlId change.
    */
    const control = useMemo(() => {
        return controls.find(c => c.controlId === controlId) || null;
    }, [controls, controlId]);

    const dropdownItems = getButtonDropdownItems(control);

    /**
     * Initialize filter query state
     * Attempts to load a saved filter from sessionStorage, falls back to default filters if not found
     */
    const [filterQuery, setFilterQuery] = useState<PropertyFilterProps.Query>(() => {
        try {
            const savedFilter = sessionStorage.getItem('savedFilterQuery');
            if (savedFilter && savedFilter != '{"tokens":[],"operation":"and"}') {
                const parsedFilter = JSON.parse(savedFilter);
                if (typeof parsedFilter === 'object' &&
                    Array.isArray(parsedFilter.tokens) &&
                    typeof parsedFilter.operation === 'string') {
                    return parsedFilter as PropertyFilterProps.Query;
                }
            }
        } catch (error) {
            console.error('Failed to parse saved filter query:', error);
        }
        return JSON.parse(controlAttributesData?.defaultFilters!);
    });

    /**
     * Effect to handle unsaved changes warning
     * Adds a 'beforeunload' event listener to warn users about unsaved changes when leaving the page
     */
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isDirty]);


    // Custom navigation prompt
    useEffect(() => {
        if (isDirty) {
            const handleBeforeNavigate = (event: PopStateEvent) => {
                if (window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
                    return;
                }
                event.preventDefault();
                history.pushState(null, '', location.pathname);
            };
            window.addEventListener('popstate', handleBeforeNavigate);
            return () => {
                window.removeEventListener('popstate', handleBeforeNavigate);
            };
        }
    }, [isDirty, location]);

    // Load saved filters from local storage on component mount
    useEffect(() => {
        const savedFilters = sessionStorage.getItem('savedFilterQuery');
        if (savedFilters) {
            setFilterQuery(JSON.parse(savedFilters));
        } else {
            // Set default filters if no saved filters exist
            setFilterQuery(JSON.parse(controlAttributesData?.defaultFilters!));
        }
    }, []);


    /**
     * Callback to update the saved filter query in sessionStorage
     */
    const updateLocalStorage = useCallback((query: PropertyFilterProps.Query) => {
        sessionStorage.setItem('savedFilterQuery', JSON.stringify(query));
    }, []);

    /**
     * Function to open a modal with given title and body
     */
    const openModal = (title: string, body: string) => {
        setModalContent({ title, body });
        setModalVisible(true);
    };

    /**
     * Memoized column definitions
     * Generates column definitions based on control attributes data and loading state
     */
    const columnDefinitions = useMemo(() => {
        const newColumnDefinitions = generateColumnDefinitions(controlAttributesData, isLoading, openModal);
        columnDefinitionsRef.current = newColumnDefinitions;
        return newColumnDefinitions;
    }, [controlAttributesData, isLoading, openModal]);

    /**
     * Custom hook to manage user preferences
    */
    const [preferences, setPreferences] = getPreferences(controlAttributesData, columnDefinitions);

    /**
     * State to manage visible columns
     * Initializes with preferences if available, otherwise uses all column IDs
     */
    const [visibleColumns, setVisibleColumns] = useState<string[]>(() => {
        if (preferences.contentDisplay) {
            return preferences.contentDisplay.filter(item => item.visible).map(item => item.id);
        }
        return columnDefinitions.map(column => column.id);
    });

    /**
     * Effect to process control data and update related states
     */
    useEffect(() => {
        const generatedItems = controlData.map((recordSet: RecordAttributes[]) =>
            recordSet.reduce((acc, record) => {
                if (record.name) {
                    acc[record.name] = record.value ?? "";
                }
                return acc;
            }, {} as Record<string, string>)
        );
        setOriginalItems(generatedItems);
        setItems(generatedItems);
        setFilteredItems(generatedItems);
    }, [controlData]);

    /**
     * Callback to handle confirmation of preference changes
     */
    const handleConfirm = useCallback(
        getHandleConfirm(setPreferences, setItemsPerPage, setVisibleColumns, setCurrentPage, columnDefinitions),
        [columnDefinitions]
    );

    /**
     * State to manage sorting
     * Initializes with the first column as the sorting column
     */
    const [sortingState, setSortingState] = useState<SortingState<Record<string, any>>>(() => ({
        sortingColumn: columnDefinitionsRef.current[0],
        isDescending: false
    }));

    /**
     * Effect to apply sorting to filtered items
     */
    useEffect(() => {
        if (sortingState) {
            setFilteredItems(prevItems => {
                return [...prevItems].sort((a, b) => {
                    const comparator = sortingState.sortingColumn.sortingComparator || (() => 0);
                    const result = comparator(a, b);
                    return sortingState.isDescending ? -result : result;
                });
            });
            setCurrentPage(1); // Reset to first page when sorting changes
        }
    }, [sortingState, controlData]);

    /**
     * Handler for sorting change events
     */
    const handleSortingChange = (event: { detail: SortingState<Record<string, any>> }) => {
        setSortingState(event.detail);
    };

    // Get the current page items
    const currentPageItems = getCurrentPageItems(currentPage, itemsPerPage, filteredItems);

    useEffect(() => {
        sessionStorage.setItem('savedFilterQuery', JSON.stringify(filterQuery));
        const newFilteredItems = filterItems(items, filterQuery);
        setFilteredItems(newFilteredItems);
        setCurrentPage(1);
    }, [filterQuery, items]);

    if (!columnDefinitions.length) {
        return <div>No data available. Please check the control configuration.</div>;
    }

    // Handle submit for each cell
    const handleSubmitEdit = useCallback(
        getHandleSubmitEdit(
            controlAttributesData,
            setModalVisible,
            setModalContent,
            setItems,
            setFilteredItems,
            alias
        ),
        [controlAttributesData, setModalVisible, setModalContent, setItems, setFilteredItems, alias]
    );

    // Handle Submitting of all edited data
    const handleSubmit = useCallback(
        getHandleSubmit(
            controlAttributesData,
            items,
            originalItems,
            controlId,
            apiCall,
            setItems,
            setFilteredItems,
            setShowBanner,
            setIsDirty
        ),
        [controlAttributesData, items, originalItems, controlId, apiCall, setItems, setFilteredItems, setShowBanner, setIsDirty]
    );

    //Handle Undo change
    const handleUndo = getHandleUndo(setItems, setFilteredItems);

    // Handle filter changes
    const handleFilterChange = useCallback((query: PropertyFilterProps.Query) => {
        setFilterQuery(query);
        updateLocalStorage(query);
    }, [updateLocalStorage]);

    // Handle pagination number change
    const handlePaginationChange = useCallback(({ detail }: { detail: { currentPageIndex: number } }) => {
        setCurrentPage(detail.currentPageIndex);
    }, []);

    return (
        <div data-testid="control-summary-table">
            {showBanner && <Flashbar items={getFlashbarMessage()} />}
            <Modal
                visible={modalVisible}
                onDismiss={() => setModalVisible(false)}
                header={modalContent.title}
            >
                <Box padding="l">
                    <TextWithLineBreaks text={modalContent.body} />
                </Box>
            </Modal>
            <Table
                renderAriaLive={({ firstIndex, lastIndex }) => {
                    const start = (currentPage - 1) * itemsPerPage + 1;
                    const end = Math.min(currentPage * itemsPerPage, filteredItems.length);
                    return `Displaying items ${start} to ${end} of ${filteredItems.length}`;
                }}
                stripedRows={true}
                header={
                    <div>
                        {isLoading && <Flashbar
                            items={[
                                {
                                    type: "warning",
                                    loading: true,
                                    content:
                                        "Data is loading. Please wait for filtering and control execution",
                                    dismissible: false,
                                    dismissLabel: "Dismiss message",
                                    onDismiss: () => setItems([]),
                                    id: "message_1"
                                }
                            ]}
                        />}

                        <Box textAlign="right" margin={{ top: "m" }}>
                            <Button iconName="download" variant="inline-link" href={`/${control?.controlType?.toLowerCase()}/${controlId}${NavigationPaths.historical}`} target="_blank"></Button>
                            <Box margin={{ left: "xs" }} display="inline-block" />
                            <ButtonDropdown
                                items={dropdownItems}
                            >
                                References
                            </ButtonDropdown>
                            <Box margin={{ left: "xs" }} display="inline-block" />
                            <Button variant="primary" onClick={handleSubmit}>Save Changes</Button>
                        </Box>
                    </div>
                }
                stickyHeader
                stickyHeaderVerticalOffset={0}
                stickyColumns={preferences.stickyColumns ? {
                    ...preferences.stickyColumns,
                    first: (preferences.stickyColumns.first ?? 0)
                } : undefined}
                wrapLines={preferences.wrapLines}
                variant="embedded"
                resizableColumns
                columnDefinitions={[
                    ...columnDefinitions.filter(col => visibleColumns.includes(col.id))
                        .sort((a, b) => visibleColumns.indexOf(a.id) - visibleColumns.indexOf(b.id))
                        .map(col => ({
                            ...col,
                            header: col.header
                        })),
                    {
                        id: 'undo',
                        header: (
                            <TableHeaderWithInfo
                                fieldName={'Undo'}
                                info={'Undo the edits performed on the row'}
                            />
                        ),
                        cell: (item) => (
                            item._originalItem ? (
                                <Button
                                    iconName="undo"
                                    variant="inline-link"
                                    onClick={() => handleUndo(item)}
                                    ariaLabel="Undo changes"
                                />
                            ) : null
                        ),
                        width: 100
                    }
                ]}
                items={currentPageItems}
                onSortingChange={handleSortingChange}
                sortingColumn={sortingState.sortingColumn}
                sortingDescending={sortingState.isDescending}
                trackBy="name"
                empty={
                    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
                        <SpaceBetween size="m">
                            <b>No resources</b>
                        </SpaceBetween>
                    </Box>
                }
                filter={<PropertyFilterComponent
                    attributesMetadata={controlAttributesData?.attributesMetadata || []}
                    onFilterChange={handleFilterChange}
                    items={items}
                    filteredItems={filteredItems}
                    controlAttributeData={controlAttributesData!}
                />}
                pagination={
                    <Pagination
                        currentPageIndex={currentPage}
                        onChange={handlePaginationChange}
                        pagesCount={Math.max(1, Math.ceil(filteredItems.length / itemsPerPage))}
                    />
                }
                submitEdit={handleSubmitEdit}
                preferences={
                    <CustomCollectionPreferences
                        preferences={preferences}
                        handleConfirm={handleConfirm}
                        columnDefinitions={columnDefinitions}
                    />
                }
            />
        </div>
    );
};
